@import 'src/styles/variables';

.image-dropzone {
  width: 100%;
  height: 100%;
}

.image-preview {
  position: relative;
  width: 100%;
  height: 100%;

  &__image {
    border-radius: 10px;
    object-fit: contain;
  }

  &__clear-button {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $color-dark-light;
    padding: 10px;

    &--icon {
      width: 16px;
      height: 16px;
    }
  }
}
