@import 'src/styles/variables';

.custom-input-container {
  .custom-input-label {
    margin-bottom: 4px;
  }

  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    .icon-show {
      position: absolute;
      left: 15px;
      color: $color-grey-1;
      border-right: 1px solid $color-grey-1;
      height: 100%;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    .end-adornment {
      position: absolute;
      right: 4px;
      top: 2px;
    }
  }

  .custom-input {
    background: $color-dark-light;
    color: $color-white;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 0 20px;
    height: 45px;
    width: 100%;
    outline: none;

    &:focus-visible {
      border: 1px solid $color-primary;
      box-shadow: none;
    }
  }

  &.icon-input {
    .custom-input {
      padding-left: 58px;
    }
  }

  &.bordered {
    .custom-input {
      border-color: $color-grey-1;

      &:focus-visible {
        border: 1px solid $color-primary;
        box-shadow: none;
      }
    }
  }

  &.error {
    .custom-input {
      border-color: $color-red;

      &:focus-visible {
        border-color: $color-red;
        box-shadow: none;
      }
    }

    .input-wrapper {
      color: $color-red;
    }
  }
}
