@import "src/styles/variables";
@import "src/styles/mixin";

.indicator-wrapper {
  position: fixed;
  top: 50%;
  left: 0;
  display: block;
  background: $color-dark-light;
  padding: 10px;
  border-radius: 0 10px 10px 0;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.3);
  z-index: 10;

  @include breakpoint-up($lg) {
    display: none;
  }

  &:hover {
    cursor: pointer;
    background: $color-dark-1;
  }
}

.mobile-sidebar {
  padding-top: 30px;

  .sidebar-menus-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 130px);
  }
}
