$font-primary: 'Helvetica';

$color-white: #fff;
$color-black: #000;

$color-primary: #4b81eb;
$color-dark: #191f27;
$color-dark-1: #28364c;
$color-grey: #f2f7ff;
$color-grey-1: #747474;
$color-grey-2: #6b6b6b;
$color-grey-3: #5a5a5a;
$color-grey-dark: #343434;
$color-dark-light: #212833;
$color-blue: #007aff;
$color-blue-dark: #4c7feb;
$color-red: #b00a0a;
$color-yellow: #ff9800;

$_3xl: 1650px;
$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 375px;

:export {
  font-primary: 'Helvetica';

  color-white: #fff;
  color-black: #000;

  color-primary: #4b81eb;
  color-dark: #191f27;
  color-dark-1: #28364c;
  color-grey: #f2f7ff;
  color-grey-1: #747474;
  color-grey-2: #6b6b6b;
  color-grey-dark: #343434;
  color-dark-light: #212833;
  color-blue: #007aff;
  color-blue-dark: #4c7feb;
  color-red: #b00a0a;
  color-yellow: #ff9800;

  _3xl: 1650px;
  xxl: 1400px;
  xl: 1200px;
  lg: 992px;
  md: 768px;
  sm: 576px;
  xs: 375px;
}
