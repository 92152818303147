@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/react-notifications-component/dist/theme.css";
@import "styles";

:root {
  font-size: 14px;
  color: $color-dark;

  @include breakpoint-up($sm) {
    font-size: 15px;
  }
  @include breakpoint-up($md) {
    font-size: 16px;
  }
}

body {
  margin: 0;
  font-family: $font-primary, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  min-height: 100vh;
  background: $color-dark;
  color: $color-white;
}

::-webkit-scrollbar {
  width: 9px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-grey-1;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-grey-2;
}

.cursor-pointer {
  cursor: pointer;
}
