.vehicle-columns-container {
  display: grid;
  gap: 8px;

  grid-template-areas:
    'documents'
    'photos'
    'recalls'
    'timeline'
    'profile';

  @media (min-width: 1080px) {
    grid-template-areas:
      'documents photos'
      'recalls recalls'
      'timeline profile';
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}

.vehicle-documents-column {
  grid-area: documents;
}

.vehicle-photos-column {
  grid-area: photos;
}

.vehicle-recalls-column {
  grid-area: recalls;
}

.vehicle-titmeline-column {
  grid-area: titmeline;
}

.vehicle-profile-column {
  grid-area: profile;
}

.vehicle-profile-gap {
  column-gap: 20px;
  row-gap: 20px;
}

.vehicle-profile-rowgap {
  column-gap: 10px;
}

.vehicle-row-gap {
  row-gap: 10px;
}

.vehicle-col-gap {
  column-gap: 10px;
}

.strike {
  position: absolute;
  height: 3px;
  background-color: white !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto auto 85px;
  padding: 0 !important;
}

.document-item {
  background: #212833;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .document-item-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
    position: relative;
    min-height: 150px;
    border: 1px dashed #747474;
    width: 100%;
    canvas {
      width: 100%;
      height: 100%;
    }
  }
}

.document-list {
  display: inline-flex;
  gap: 20px;
  .document-item-container,
  .dragger {
    width: 185px;
  }
}

.vehicle-detail-navigation {
  display: flex;
  align-items: center;
}
.vehicle-detail-vin {
  font-size: medium !important;
  font-weight: 700;
  margin-left: 4rem;
  color: white !important;
}
