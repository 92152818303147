.custom-select-container {
  .custom-select-label {
    margin-bottom: 4px;
  }
  flex: 1;
}

.delete-icon {
  padding: 2px;
  height: 24px;
  width: 24px;
  color: #CCCCCC;
  cursor: pointer;
}

.delete-icon:hover {
  color: #888888;
}