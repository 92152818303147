@import 'src/styles/variables';

.expected-fields {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 4px;

  &__item {
    list-style-type: none;
    padding: 4px;
    border: 1px solid $color-grey-dark;
    border-radius: 4px;
  }
}
