@import "src/styles/variables";

.agree-modal {
  .agree-link {
    color: $color-white;

    &:hover {
      color: $color-blue-dark;
    }
  }
}
