@import "src/styles/variables";

.image-container {
  position: relative;
}

.image-close {
  width: 100px;
  text-align: center;
  border-radius: 0 0 10px 10px ;
}

:hover.image-close {
  cursor: pointer;
}