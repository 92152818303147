@import "src/styles/variables";
.custom-switch {
  .MuiTypography-root {
    color: $color-white!important;
  }
  .MuiTypography-root {
    color: $color-white;
    &.Mui-disabled {
      opacity: 0.8;
    }
  }
}
