@import "src/styles/variables";

.buyers-table-container {
  background: $color-dark-light;
  border-radius: 10px;
  padding: 30px 20px;

  .table {
    color: $color-white;
  }
}
