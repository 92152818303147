@import 'src/styles/variables';

.otp-input {
  display: flex;
  align-items: center;
}

.otp-digit {
  width: 3rem !important;
  height: 3.25rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  @media (max-width: 640px) {
    width: 2.25rem !important;
    height: 2.5rem;
  }

  &:focus-visible {
    border: 1px solid $color-primary;
    box-shadow: none;
  }
}
