@import 'src/styles/variables';

.tabs-menu {
  &__tab {
    &--selected {
      border-bottom: 2px solid $color-primary;
      color: $color-primary;
    }
  }
}
