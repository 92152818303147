.items-list {
  display: inline-flex;
  gap: 20px;

  &.is-dragging {
    transform: none !important;
    transition: none !important;
  }
  .dragger {
    width: 240px;
    align-self: flex-end;
  }
}

.dropper {
  width: auto;
  height: auto;
  overflow: auto;
  min-height: 22rem;
}
