@import "src/styles/mixin";
@import "src/styles/variables";

.main-layout {
  .main-container {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .sidebar-container {
    margin-right: 40px;
    flex-shrink: 0;
    display: none;

    .page-sidebar {
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0 ,0 ,0 ,0.12);
    }

    @include breakpoint-up($lg) {
      display: block;
    }
  }

  .main-content {
    flex: 1;
    max-width: 100%;

    @include breakpoint-up($lg) {
      max-width: calc(100% - 320px);
    }
  }
}
