@import 'src/styles/variables';
@import 'src/styles/mixin';

.page-header {
  padding: 20px 0;
  background: $color-dark-light;
  box-shadow: 0 7px 5px 0 rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;

  .logo {
    margin-right: auto;
  }

  .header-link {
    color: $color-white;
    cursor: pointer;

    &:hover {
      color: $color-blue-dark;
    }

    &.active {
      color: $color-primary;
    }
  }

  .header-profile-photo {
    object-fit: cover;
  }

  .logout-container {
    margin-left: 20px;
  }

  .pc-menus {
    display: none;

    @include breakpoint-up($md) {
      display: flex;
    }
  }

  .mobile-menus {
    display: flex;

    @include breakpoint-up($md) {
      display: none;
    }
  }

  .collapsable-menu {
    margin-top: 2rem;

    .menu-list__item {
      transition: all 0.1s ease-in;
      color: $color-grey;
      display: flex;
      padding: 10px 20px;
      border-radius: 10px;
      margin-bottom: 4px;

      &:not(.active):hover {
        background: $color-dark-1;
      }
    }
  }
  .dropdown-toggle {
    &.nav-link {
      color: #fff;
      &:hover {
        color: #4c7feb;
      }
    }
  }
  .vin-button {
    width: 40px;
  }
}
