.secondary-address-details > summary {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 1.5rem;

  &::marker {
    display: none;
    content: '';
  }

  h1 {
    margin-bottom: 0;
  }

  .summary-icon {
    transition: all 0.25s ease-in-out;
  }
}

.secondary-address-details[open] > summary {
  .summary-icon {
    transform: rotate(90deg);
  }
}
