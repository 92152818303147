.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fw-w {
  flex-wrap: wrap;
}

.w-screen {
  width: 100vw;
}

.h-screen {
  height: 100vh;
}

.col-3 {
  flex-basis: 33.3333%;
  width: 33.3333%;
}

.text-of-h {
  text-overflow: ellipsis;
  overflow: hidden;
}

.max-md-w-50 {
  @include breakpoint-up($md) {
    max-width: 50%;
  }
}

.container {
  @include breakpoint-up($_3xl) {
    max-width: 1560px;
  }
}

.dropdown-menu {
  background: $color-dark-light;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.3);;
}

.dropdown-item {
  color: $color-white;
  padding: 8px 20px;

  &:focus,
  &:hover {
    background: $color-dark-1;
    color: $color-white;
  }

  &:active {
    background: $color-primary;
    color: $color-white;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.flex {
  flex: 1;
}

.w-1\/2 {
  width: 50%;
}
