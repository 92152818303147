.text-xxs {
  font-size: 0.625rem !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.43 !important;
}

.text-md {
  font-size: 1rem !important;
  line-height: 1.4375 !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-2xl {
  font-size: 1.375rem !important;
}

.text-3xl {
  font-size: 1.5rem !important;
}

.text-4xl {
  font-size: 1.625rem !important;
}
