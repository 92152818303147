@import "src/styles/variables";

.custom-textarea-container {
  .custom-input-label {
    margin-bottom: 4px;
  }

  .textarea-wrapper {

  }

  .custom-textarea {
    background: $color-dark-light;
    color: $color-white;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    outline: none;
  }

  &.bordered {
    .custom-textarea {
      border-color: $color-grey-1;
    }
  }

  &.error {
    .custom-textarea {
      border-color: $color-red;
    }
  }
}
