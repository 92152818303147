@import 'src/styles/variables';

.scroll-container {
  cursor: grab;
}

.zoom-btn-group {
  position: absolute;
  display: flex;
  gap: 8px;
  top: 16px;
  right: 16px;
}

.zoom-btn {
  color: $color-white;
  opacity: 50%;
}
