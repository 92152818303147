@import 'src/styles/variables';

.vehicle-table-container {
  background: $color-dark-light;
  border-radius: 10px;
  padding: 30px 20px;

  .table {
    color: $color-white;

    .vehicle-fixed-size-column {
      max-width: 70px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.edit-vehicle {
  a {
    color: $color-white;
  }
}

.flashing {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.hovertext {
  position: relative;
}

.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: max-content;
  background-color: #191f27;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  transition: opacity 1s ease-in-out;
  position: absolute;
  top: 90%;
}

.hovertext:hover:before {
  opacity: 1;
  visibility: visible;
}