@import 'src/styles/variables';

.register-form {
  width: 900px;
  padding: 15px 30px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  background: $color-dark-light;

  .agree-term-link {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
