@import 'src/styles/variables';

.vehicle-profile-column-container {
  display: flex;
  flex-direction: column;
  background-color: $color-dark-light;
  padding: 1rem;
}

.vehicle-profile-column-header {
  border-bottom: 5px solid $color-dark;
}
