@import "src/styles/variables";

.photo-upload-form {
  .drop-box {
    border: 1px dashed $color-grey-1;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 200px;
    outline: none;
    position: relative;

    label {
      cursor: inherit;
    }

    .image-preview {
      max-width: 100%;
      height: auto;
      max-height: 300px;
      width: auto;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &:hover .remove-btn{
      display: flex;
    }

    .remove-btn {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.7);

      &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.5);
      }

      .remove-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}
