.vehicle-profile-gap {
  column-gap: 20px;
  row-gap: 20px;
}

.vehicle-profile-rowgap {
  column-gap: 10px;
}

.vehicle-row-gap {
  row-gap: 10px;
}

.vehicle-col-gap {
  column-gap: 10px;
}

.vehicle-recalls-container {
  max-width: calc(100vw - 4rem);
}

.strike {
  position: absolute;
  height: 3px;
  background-color: white !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto auto 85px;
  padding: 0 !important;
}

.document-item {
  background: #212833;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .document-item-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
    position: relative;
    min-height: 150px;
    border: 1px dashed #747474;
    width: 100%;
    .handle {
      position: absolute;
      color: var(--bs-body-color);
      top: 0;
      cursor: move;
      opacity: 0;
    }
    &:hover {
      .handle {
        opacity: 1;
      }
    }

    canvas {
      width: 100%;
      height: 100%;
    }

    &:hover {
      .document-actions {
        display: flex;
      }
    }

    .document-actions {
      position: absolute;
      display: none;
      align-items: center;

      &__btn {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.7);

        &:hover {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.5);
        }

        .btn-icon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.documents-list {
  max-width: calc(100vw - 4rem);
  .dragger {
    width: 185px;
  }
}

.vehicle-detail-navigation {
  display: flex;
  align-items: center;
}
.vehicle-detail-vin {
  font-size: medium !important;
  font-weight: 700;
  margin-left: 4rem;
  color: white !important;
}
