@import "src/styles/variables";

.signature-pad-container {


  .pad-wrapper {
    position: relative;
    height: 200px;

    canvas {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    .pad-clear-button {
      position: absolute;
      right: 10px;
      top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: $color-dark-light;
      padding: 10px;

      .clear-icon {
        width: 16px;
        height: 16px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}
