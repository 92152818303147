@import 'src/styles/variables';

.photo-card {
  background: $color-dark-light;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .photo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    position: relative;
    min-height: 150px;
    border: 1px dashed $color-grey-1;
    width: 100%;
    isolation: isolate;
    .handle {
      position: absolute;
      top: 0;
      cursor: move;
      opacity: 0;
      z-index: 10;
    }
    &:hover {
      .handle {
        opacity: 1;
      }
    }

    .photo-img {
      max-width: 100%;
      height: auto;
      max-height: 150px;
      width: auto;
    }

    &:hover {
      .photo-img {
        opacity: 0.7;
      }

      .photo-actions {
        display: flex;
      }
    }

    .loading {
      position: absolute;
    }

    .photo-actions {
      position: absolute;
      display: none;
      align-items: center;

      &__btn {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.7);

        &:hover {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.5);
        }

        .btn-icon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.dragging {
  opacity: 0.7;
}

.dropOver {
  background: #303a4a;
}
