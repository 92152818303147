.account_permissions-details__wrapper > summary {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 1.5rem;

  &::marker {
    display: none;
    content: '';
  }

  h1 {
    margin-bottom: 0;
  }

  .summary-icon {
    transition: all 0.25s ease-in-out;
  }
}

.account_permissions-details__wrapper[open] > summary {
  .summary-icon {
    transform: rotate(90deg);
  }
}

.account_permissions-details__content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 4px;

  .account_permissions-details__fieldset {
    padding: 4px;
  }
}
