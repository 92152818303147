@import 'src/styles/variables';

.password-input {
  &__toggle-button {
    color: $color-grey-1;
  }

  &__toggle-icon {
    font-size: 20px;
  }
}
