@import 'src/styles/variables';

.icon-button {
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }

  &.disabled {
    pointer-events: none;
    color: $color-grey-dark;
  }
}
