@import 'src/styles/variables';

.datepicker-container {
  label {
    margin-bottom: 4px;
  }

  .datepicker-wrapper {
    .datepicker-input-wrapper {
      border: 1px solid transparent;
      border-radius: 8px;
      height: 45px;
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;

      &.bordered {
        border-color: $color-grey-1;
      }

      &.error {
        border-color: $color-red;
        color: $color-red;
      }

      .icon-wrapper {
        position: absolute;
        right: 10px;
        color: black;
      }

      input {
        &:focus {
          & + .presentational-input {
            outline: 1px solid $color-primary;
            outline: 5px auto -webkit-focus-ring-color;
          }
        }
        &:hover {
          & + .presentational-input {
            opacity: 0.8;
          }
        }
      }

      &.dark {
        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
          z-index: 2;
          /* Allow the select to span the full height in Safari */
          -webkit-appearance: none;
        }

        .presentational-input {
          color: $color-white;
          background: $color-dark-light;
          padding-left: 20px;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          display: flex;
          align-items: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .presentational-placeholder {
            color: $color-grey-2;
          }
        }

        .datepicker-clear-button {
          position: absolute;
          top: 0;
          right: 30px;
          z-index: 3;

          &:hover {
            background: revert;
            opacity: 0.8;
          }
        }

        .icon-wrapper {
          color: inherit;
          display: flex;
          justify-content: space-between;
          align-items: center;
          z-index: 1;
        }
      }
    }

    .rdtPicker {
      color: $color-black;
      border: none;
      border-radius: 8px;
      margin-top: 8px;
    }

    &.dark {
      .rdtPicker {
        color: $color-white;
        background: $color-dark-1;

        .rdtPrev,
        .rdtSwitch,
        .rdtNext,
        .rdtYear,
        .rdtMonth,
        .rdtDay {
          &:hover {
            background: $color-dark-light;
          }
        }

        .rdtActive {
          background: $color-primary;
        }
      }
    }
  }
}
