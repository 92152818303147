.bg-dark-light {
  background: $color-dark-light;
}

.text-dark {
  color: $color-dark-1;
}

.text-grey {
  color: $color-grey;
}

.text-grey-dark {
  color: $color-grey-1;
}

.text-white {
  color: $color-white;
}

.text-red {
  color: $color-red;
}

.text-yellow {
  color: $color-yellow;
}
