@import "src/styles/variables";

.page-sidebar {
  background: $color-dark-light;
  border-radius: 10px;
  padding: 30px 20px;
  width: 270px;
  position: sticky;
  overflow-y: scroll;
  height: calc(100vh - 140px);
  top: 150px;

  .menu-list {
    display: flex;
    flex-direction: column;

    &__item {
      color: $color-grey;
      display: flex;
      padding: 10px 20px;
      border-radius: 10px;
      margin-bottom: 10px;

      &.active {
        background: $color-primary;
        color: $color-white;
      }

      &:not(.active):hover {
        background: $color-dark-1;
      }
    }
  }
}
