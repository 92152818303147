@import "src/styles/variables";

.custom-select-container {
  .custom-select-label {
    margin-bottom: 4px;
  }

  .custom-select-wrapper {
    .select-content {
      background: $color-dark-light;
      color: $color-white;
      border: 1px solid transparent;
      border-radius: 8px;
      padding: 0 40px 0 20px;
      height: 45px;
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;

      &.bordered {
        border-color: $color-grey-1;
      }

      &.error {
        border-color: $color-red;
        color: $color-red;
      }

      &__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.select-placeholder {
          color: $color-grey-2;
        }
      }

      .down-icon {
        position: absolute;
        right: 10px;
        transition: all 0.2s ease-in-out;

        &.opened {
          transform: rotate(180deg);
        }
      }

      &:not(.loading):hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    &.bordered {
      border: 1px solid $color-grey-1;
      border-radius: 8px;
    }

    .select-form-control {
      display: none;
    }

    .select-dropdown-container {
      position: relative;

      .select-dropdown-list {
        width: 100%;
        max-height: 400px;
        overflow: auto;
        position: absolute;
        top: 10px;
        display: flex;
        flex-direction: column;
        padding: 12px 6px;
        background: $color-dark-light;
        border-radius: 8px;
        box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.1);
        z-index: 8;

        &__item {
          padding: 10px;
          border-radius: 10px;
          color: white;
          text-decoration: none;

          &:hover {
            cursor: pointer;
            background: $color-dark-1;
          }
        }

        .no-options {
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .select-spinner {
    width: 1.25rem;
    height: 1.25rem;
    border-width: 2px;
    color: $color-grey-1;
  }
  &.dark {
    .custom-select-wrapper {
      .select-content {
        background: $color-dark;
      }
    }
  }
}
