@mixin breakpoint-up($min) {
  @media (min-width: $min) {
    @content;
  }
}

@mixin breakpoint($max) {
  @media (max-width: $max) {
    @content;
  }
}
