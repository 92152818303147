@import 'src/styles/variables';

.custom-btn {
  outline: none;
  border: none;
  background: $color-primary;
  color: $color-white;
  border-radius: 30px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  min-width: 100px;
  padding: 0 20px;
  position: relative;

  &:not(:disabled):hover {
    background: $color-blue;
    cursor: pointer;
  }

  &:disabled {
    background: $color-dark-1;
    color: $color-grey-2;
  }

  &:focus-visible {
    outline: 1px solid $color-white;
    outline-offset: 2px;
  }

  .spinner-border {
    border-width: 2px;
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    color: $color-grey-2;
  }

  &.loading {
    color: #ffffff00;
  }

  &.link-btn {
    height: initial;
    min-width: initial;
    padding: 10px 0;
    border-radius: 0;
    background: transparent !important;

    &:hover {
      text-decoration: underline;
      opacity: 0.8;
    }
  }

  &.dark-btn {
    background: $color-dark-light;

    &:not(:disabled):hover {
      background: $color-dark-1;
      cursor: pointer;
    }
  }

  &.cancel-btn {
    background: $color-grey-1;

    &:not(:disabled):hover {
      background: $color-grey-3;
    }
  }
}
