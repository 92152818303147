a {
  color: $color-primary;
  text-decoration: none;

  &:hover {
    color: $color-blue;
  }
}

.modal-dialog {
  .modal-content {
    background: $color-dark;

    .modal-header {
      .btn-close {
        color: $color-white;
      }
    }
  }
}


.table {
  color: $color-white;

  & > :not(:first-child) {
    border: none;
  }

  th, td {
    border: none;
    padding: 10px 15px;
    vertical-align: middle;
  }

  tbody {
    border: none;
  }

  tbody tr {
    &:nth-child(2n + 1) {
      background: $color-dark;
      color: $color-white;

      td {
        color: $color-white;
      }
    }
  }
}

.small-image {
  object-fit: cover;
  border: 1px solid #fdfdfd;
  cursor: pointer;
}

.image-modal {
  position: fixed;
  z-index: 1;
  right: 0;
  top: 98px;
  width: 750px;
  height: 450px;
  overflow: auto;
  background-color: #fefefe;
  resize: both;
  direction: rtl;

  .modal-content {
    position: relative;

    img {
      width: auto;
      height: auto;
    }

    .close {
      color: #212833;
      font-size: 25px;
      font-weight: bold;
      position: fixed;
      right: 20px;
      background-color: white;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      margin-top: 10px;
    }

    .close:hover,
    .close:focus {
      color: #32404d;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
